import { axios } from '@/utils/request';
/**
 * 获取用户列表
 */
export function getUserList(params) {
  return axios({
    url: '/sysUser/page',
    method: 'get',
    params
  });
}
/**
 * 获取部门树结构
 */
export function getOrgTree(params) {
  return axios({
    url: '/sysOrg/tree',
    method: 'get',
    params
  });
}
/**
 * 获取职位下拉列表
 */
export function getPosList(params) {
  return axios({
    url: '/sysPos/list',
    method: 'get',
    params
  });
}
/**
 * 新增/编辑用户
 */
export function postUserInfo(data) {
  return axios({
    url: '/sysUser/addOrEdit',
    method: 'post',
    data
  });
}
/**
 * 删除用户
 */
export function deleteUsers(data) {
  return axios({
    url: '/sysUser/delete',
    method: 'post',
    data
  });
}
/**
 * 获取用户详情
 */
export function getUserDetail(params) {
  return axios({
    url: '/sysUser/detail',
    method: 'get',
    params
  });
}
/**
 * 切换用户状态
 */
export function postUserStatus(data) {
  return axios({
    url: '/sysUser/changeStatus',
    method: 'post',
    data
  });
}
/**
 * 重置密码
 */
export function postResetPassword(data) {
  return axios({
    url: '/sysUser/resetPwd',
    method: 'post',
    data
  });
}
/**
 * 获取角色列表
 */
export function getRoleList(params) {
  return axios({
    url: '/sysRole/dropDown',
    method: 'get',
    params
  });
}
/**
 * 获取用户所拥有的角色列表
 */
export function getUserOwnRole(params) {
  return axios({
    url: '/sysUser/ownRole',
    method: 'get',
    params
  });
}
/**
 * 分配角色
 */
export function postGrantRole(data) {
  return axios({
    url: '/sysUser/grantRole',
    method: 'post',
    data
  });
}

<template>
  <div class="equip-container">
    <div class="title">系统管理员管理</div>
    <div class="query-nav">
      <el-form ref="queryFormRef" inline :model="queryForm">
        <el-form-item prop="name">
          <el-input v-model.trim="queryForm.name" placeholder="请输入机构名称/姓名"></el-input>
        </el-form-item>
        <el-form-item prop="status">
          <el-select v-model='queryForm.status' placeholder='请选择'>
            <el-option label='冻结' :value='1'></el-option>
            <el-option label='解冻' :value='0'></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="buts">
      <el-button type="primary" icon="el-icon-plus" @click="$refs.infoDialogRef.open()">新建</el-button>
    </div>
    <div class="table">
      <el-table
        stripe
        size="medium"
        :data="userList"
        v-loading="tableLoading"
      >
        <el-table-column align="center" width="60" type="index" fixed label="序号"></el-table-column>
        <el-table-column align="center" label="姓名" prop="name" show-overflow-tooltip>
          <template slot-scope="{ row }">{{row.name || '--'}}</template>
        </el-table-column>
        <el-table-column align="center" label="电话" prop="adminPhone">
          <template slot-scope="{ row }">{{row.adminPhone || '--'}}</template>
        </el-table-column>
        <el-table-column align="center" label="管理机构" prop="adminOrgName" width="220" show-overflow-tooltip>
          <template slot-scope="{ row }">{{row.adminOrgName || '--'}}</template>
        </el-table-column>
        <el-table-column align="center" label="管理员状态" prop="status">
          <span v-if="row.adminOrgName" slot-scope="{ row }">
            <el-switch
              class="table-scope"
              v-model="row.status"
              :active-value="0"
              active-text="解冻"
              active-color="#67C33A"
              :inactive-value="1"
              inactive-text="冻结"
              inactive-color="#F56C6C"
              @change="handleChangeStatus($event, row.id)"
            ></el-switch>
          </span>
          <span v-else>--</span>
        </el-table-column>
        <el-table-column align="center" label="操作" width="240" fixed="right">
          <template slot-scope="{ row }">
            <div class="comm-operation">
              <span class="edit-btn" @click="$refs.dispatchDialogRef.open(row)">分配机构</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页区域 -->
    <div class="page">
      <el-pagination
        background
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        :page-sizes="[10, 20, 30, 50]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, prev, pager, next, sizes"
      ></el-pagination>
    </div>
    <!-- 新增 -->
    <InfoDialog ref="infoDialogRef" @onSuccess="handleSuccess" />
    <!-- 分配大屏 对话框 -->
    <dispatch-dialog ref="dispatchDialogRef" @ok="handleOk"></dispatch-dialog>
  </div>
</template>

<script>
import { getOrgUserList, enableOrDisableUser } from '@/api/main/adminMana';
import InfoDialog from './infoDialog.vue';
import dispatchDialog from '@/views/main/adminMana/managerMana/dispatchDialog';
import { postUserStatus } from '@/api/main/system/userMana';
export default {
  name: 'roleMana',
  components: {
    InfoDialog, dispatchDialog
  },
  data() {
    return {
      total: 0,
      queryForm: {
        name: '',
        status: null,
        pageNo: 1,
        pageSize: 10
      },
      userList: [],
      tableLoading: false,
    };
  },
  created() {
    this.getUserListData();
  },
  methods: {
    // 获取角色列表
    getUserListData() {
      this.tableLoading = true;
      getOrgUserList(this.queryForm)
        .then(res => {
          this.userList = res.data.records;
          this.total = res.data.total;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 搜索
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.getUserListData();
    },
    // 重置
    handleReset() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.queryForm.name = '';
      this.queryForm.status = null
      this.getUserListData()
    },
    // 修改状态
    handleChangeStatus(status, id) {
      let str = '';
      switch (status) {
        case 0:
          str = '解冻';
          break;
        case 1:
          str = '冻结';
          break;
      }
      console.log(status,id);
      enableOrDisableUser({ status, id}).then(res => {
        this.$message.success(`已${str}此管理员 ！`);
      });
    },
    // 分页
    handleSizeChange(val) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = val;
      this.getUserListData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNo = val;
      this.getUserListData();
    },
    // 新增成功
    handleSuccess() {
      this.handleReset()
    },
    handleOk() {
      this.getUserListData()
    },
  }
};
</script>

<style lang="less" scoped>
.equip-container {
  .page {
    margin: 16px 0;
    float: right;
  }
}
.query-nav {
  border-bottom: 1px solid #dcdfe7;
  border-top: 1px solid #dcdfe7;
  padding-top: 24px;
}
.buts {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #117bf7;
  }
}
.table {
  margin-top: 12px;
}
.title {
  padding: 0 16px 12px;
  font-size: 16px;
  font-weight: bold;
}
/deep/.table-scope {
  min-width: 128px;
  .el-switch__label--left {
    position: relative;
    left: 58px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__core {
    width: 52px !important;
  }

  .el-switch__label--right {
    position: relative;
    right: 57px;
    color: #fff;
    z-index: -1111;
    font-size: 12px;
  }

  .el-switch__label--right.is-active {
    z-index: 3;
    color: #fff !important;
  }

  .el-switch__label--left.is-active {
    z-index: 3;
    color: #fff !important;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"equip-container"},[_c('div',{staticClass:"title"},[_vm._v("系统管理员管理")]),_c('div',{staticClass:"query-nav"},[_c('el-form',{ref:"queryFormRef",attrs:{"inline":"","model":_vm.queryForm}},[_c('el-form-item',{attrs:{"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"请输入机构名称/姓名"},model:{value:(_vm.queryForm.name),callback:function ($$v) {_vm.$set(_vm.queryForm, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"queryForm.name"}})],1),_c('el-form-item',{attrs:{"prop":"status"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.queryForm.status),callback:function ($$v) {_vm.$set(_vm.queryForm, "status", $$v)},expression:"queryForm.status"}},[_c('el-option',{attrs:{"label":"冻结","value":1}}),_c('el-option',{attrs:{"label":"解冻","value":0}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_c('el-button',{on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)],1),_c('div',{staticClass:"buts"},[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$refs.infoDialogRef.open()}}},[_vm._v("新建")])],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],attrs:{"stripe":"","size":"medium","data":_vm.userList}},[_c('el-table-column',{attrs:{"align":"center","width":"60","type":"index","fixed":"","label":"序号"}}),_c('el-table-column',{attrs:{"align":"center","label":"姓名","prop":"name","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name || '--'))]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"电话","prop":"adminPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.adminPhone || '--'))]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"管理机构","prop":"adminOrgName","width":"220","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.adminOrgName || '--'))]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"管理员状态","prop":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return (row.adminOrgName)?_c('span',{},[_c('el-switch',{staticClass:"table-scope",attrs:{"active-value":0,"active-text":"解冻","active-color":"#67C33A","inactive-value":1,"inactive-text":"冻结","inactive-color":"#F56C6C"},on:{"change":function($event){return _vm.handleChangeStatus($event, row.id)}},model:{value:(row.status),callback:function ($$v) {_vm.$set(row, "status", $$v)},expression:"row.status"}})],1):_c('span',[_vm._v("--")])}}],null,true)}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"240","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"comm-operation"},[_c('span',{staticClass:"edit-btn",on:{"click":function($event){return _vm.$refs.dispatchDialogRef.open(row)}}},[_vm._v("分配机构")])])]}}])})],1)],1),_c('div',{staticClass:"page"},[_c('el-pagination',{attrs:{"background":"","total":_vm.total,"current-page":_vm.queryForm.pageNo,"page-size":_vm.queryForm.pageSize,"page-sizes":[10, 20, 30, 50],"layout":"total, prev, pager, next, sizes"},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('InfoDialog',{ref:"infoDialogRef",on:{"onSuccess":_vm.handleSuccess}}),_c('dispatch-dialog',{ref:"dispatchDialogRef",on:{"ok":_vm.handleOk}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
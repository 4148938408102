import { axios } from '@/utils/request';
/**
 * 机构列表
 */
export function getOrgList(parameter) {
  return axios({
    url: '/org/list',
    method: 'get',
    params: parameter
  });
}
/**
 * 新增机构
 */
export function addOrg(parameter) {
  return axios({
    url: '/org/add',
    method: 'post',
    data: parameter
  });
}
/**
 * 编辑机构
 */
export function editOrg(parameter) {
  return axios({
    url: '/org/edit',
    method: 'post',
    data: parameter
  });
}
/**
 * 查询可分配的管理员
 */
export function getGrantUser(parameter) {
  return axios({
    url: '/sysUser/getGrantUser',
    method: 'get',
    params: parameter
  });
}
/**
 * 分配机构管理员
 */
export function grantOrgUser(parameter) {
  return axios({
    url: '/sysUser/grantOrgUser',
    method: 'post',
    data: parameter
  });
}

/**
 * 用户列表
 */
export function getOrgUserList(parameter) {
  return axios({
    url: '/org/userList',
    method: 'get',
    params: parameter
  });
}
/**
 * 添加系统管理员
 */
export function addAdminUser(parameter) {
  return axios({
    url: '/sysUser/addAdminUser',
    method: 'post',
    data: parameter
  });
}
/**
 * 用户查询可选的机构
 */
export function getChoseOrgList(parameter) {
  return axios({
    url: '/org/choseOrg',
    method: 'get',
    params: parameter
  });
}
/**
 * 用户分配机构
 */
export function grantOrg(parameter) {
  return axios({
    url: '/sysUser/grantOrg',
    method: 'post',
    data: parameter
  });
}
/**
 * 激活或冻结用户
 */
export function enableOrDisableUser(parameter) {
  return axios({
    url: '/sysUser/enableOrDisableUser',
    method: 'post',
    data: parameter
  });
}





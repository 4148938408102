<template>
  <!-- 新增管理员 -->
  <el-dialog
    title="新增管理员"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onClose"
  >
    <el-form ref="paramsFormRef" label-width="80px" :model="paramsForm" :rules="rules">
      <el-form-item label="姓名" prop="name">
        <el-input v-model.trim="paramsForm.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model.number="paramsForm.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onClose">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { addAdminUser } from '@/api/main/adminMana';
export default {
  data() {
    let checkPhone = (rule, value, callback) => {
      setTimeout(() => {
        if (/^1[3456789]\d{9}$/.test(value) === false) {
          callback(new Error('请输入正确的手机号'));
        } else {
            callback();
        }
      }, 500);
    };
    return {
      dialogVisible: false,
      submitLoading: false,
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { max: 50, message: '长度在 50 个字符以内', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入电话', trigger: 'blur' },
          { validator: checkPhone, trigger: 'blur' }
        ]
      },
      paramsForm: {
        name: '',
        phone: '',
      }
    };
  },
  methods: {
    open() {
      this.dialogVisible = true;
    },
    onClose() {
      this.dialogVisible = false;
      this.$refs.paramsFormRef.resetFields();
    },
    onSubmit() {
      this.$refs.paramsFormRef.validate(valid => {
        if (!valid) return;
        this.submitLoading = true;
        addAdminUser(this.paramsForm)
          .then(res => {
            this.$message.success('新增管理员成功！');
            this.$emit('onSuccess', 1);
            this.onClose();
          })
          .finally(() => {
            this.submitLoading = false;
          });
      });
    }
  }
};
</script>

<template>
  <el-dialog
    title="分配机构"
    :visible.sync="dialogVisible"
    width="560px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onCancel"
  >
    <el-form ref="paramsFormRef" label-width="120px" :model="paramsForm">
      <el-form-item label="管理员姓名" prop="name">
        <el-input v-model="paramsForm.name" disabled></el-input>
      </el-form-item>
      <el-form-item label="分配机构">
        <el-select v-model="paramsForm.grantOrgIdList" multiple placeholder="请选择" :popper-append-to-body="false">
          <el-option
            v-for="item in origination"
            :key="item.orgId"
            :label="item.orgName"
            :value="item.orgId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel">取消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onConfirm">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getChoseOrgList, grantOrg } from '@/api/main/adminMana';
export default {
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      origination: [],
      paramsForm: {
        name: '',
        grantOrgIdList: []
      },
    };
  },
  methods: {
    open(row) {
      this.paramsForm.name = row.name
      this.dialogVisible = true;
      this.paramsForm.id = row.id
      getChoseOrgList({userId: row.id}).then(res => {
        let data = res.data;
        this.origination = data;
        this.paramsForm.grantOrgIdList = data.filter(item => item.checked).map(item => item.orgId);
      })
    },
    // 取消
    onCancel() {
      this.dialogVisible = false;
    },
    // 确定
    onConfirm() {
      this.submitLoading = true;
      grantOrg(this.paramsForm)
        .then(res => {
          this.$message.success('分配成功！');
          this.$emit('ok',1)
          this.onCancel();
        })
        .finally(() => {
          this.submitLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-loading-mask {
  background-color: #fff;
}

.el-tree {
  height: 600px;
  overflow: auto
}
</style>
